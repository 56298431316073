import { isEmpty, isIOS } from '../../utils/helper.js';
import { initializeCache, getTexture, hasTexture, isLoadingTexture } from './asset-cache.js';
import { setVirtualPageviewBaseUrl, setVirtualPageview } from '../../utils/analytics';

// uncomment or set starting viewpoint for debug purposes, for example: CubeMAPS-AU_003
// const DEBUG_ROOT_ID = 'CubeMAPS-AU_002';
// uncomment to hide the introduction screen for debugging purposes
// const DEBUG_SHOW_INTRODUCTION = false;

AFRAME.registerComponent('scene-loader', {
  schema: {
    contentRoot: { default: '#scene-contents' },
    content: { default: { 'root-viewpoint-id': null, 'viewpoint-marks': [] } },
  },

  init: function () {
    initializeCache();

    this.sceneData = {
      textureLeft: null,
      textureRight: null,
      previousViewpointId: null,
      currentViewpointId: null,
      viewpointPosition: null,
    };

    this.panoramasPreloaded = false;
    this.textureLoadCounter = 0;
    this.viewpointId = undefined;
    this.viewpointMap = new Map();
    this.hotspotMap = new Map();
    this.isLoading = false;
    this.viewpointIds = [];
    this.preloadId = 0;
    this.bindMethods();
  },

  update: function (_) {
    if (this.data.content['root-viewpoint-id'] != null) {
      this.initialize();
      let rootId;

      if (typeof DEBUG_ROOT_ID !== 'undefined') {
        rootId = DEBUG_ROOT_ID;
      } else {
        rootId = this.data.content['root-viewpoint-id'];
      }

      this.loadBegin(rootId);
    }
  },

  remove: function () {
    this.removeEventListener();
  },

  play: function () {
    this.addEventListener();
  },

  pause: function () {
    this.removeEventListener();
  },

  bindMethods: function () {
    this.onLoadViewpoint = AFRAME.utils.bind(this.onLoadViewpoint, this);
  },

  addEventListener: function () {
    window.addEventListener('loadviewpoint', this.onLoadViewpoint);
  },

  removeEventListener: function () {
    window.removeEventListener('loadviewpoint', this.onLoadViewpoint);
  },

  initialize: function () {
    //set analytics base URL
    setVirtualPageviewBaseUrl('virtualtradefair');

    // add the root viewpoint
    let rootId = this.data.content['root-viewpoint-id'];
    this.viewpointIds.push(rootId);

    // automatically setup viewpoint connections
    const count = this.data.content['viewpoint-count'];
    const base = this.data.content['viewpoint-prefix'];
    const to = 'to_';
    const digits = 3;

    for (let i = 1; i <= count; ++i) {
      const digitsA = this.addLeadingCharacters(i, digits, '0');
      const viewpointA = base + digitsA;
      const toA = to + digitsA;

      this.viewpointIds.push(viewpointA);

      for (let j = i + 1; j <= count; ++j) {
        const digitsB = this.addLeadingCharacters(j, digits, '0');
        const viewpointB = base + digitsB;
        const toB = to + digitsB;

        let linkA = viewpointA + '__' + toB;
        let linkB = viewpointB + '__' + toA;

        this.viewpointMap.set(linkA, viewpointB);
        this.viewpointMap.set(linkB, viewpointA);
      }
    }

    this.showLoadingIndicator(true);

    this.textureLoadCounter = 2;
    getTexture(
      `${rootId}_l`,
      this.getPanoramaPath(`${rootId}_l`),
      { mipmaps: false },
      this.rootTextureLoaded.bind(this),
      this.rootTextureLoaded.bind(this)
    );
    getTexture(
      `${rootId}_r`,
      this.getPanoramaPath(`${rootId}_r`),
      { mipmaps: false },
      this.rootTextureLoaded.bind(this),
      this.rootTextureLoaded.bind(this)
    );

    // add all hotspots to a map
    for (let i = 0; i < this.data.content['hotspots'].length; ++i) {
      let current = this.data.content['hotspots'][i];
      if (!this.hotspotMap.has(current.id)) {
        this.hotspotMap.set(current.id, current);
      }
    }
  },

  addLeadingCharacters: function (num, size, char) {
    let s = num + '';
    while (s.length < size) {
      s = char + s;
    }
    return s;
  },

  rootTextureLoaded: function () {
    this.textureLoadCounter -= 1;

    if (this.textureLoadCounter === 0) {
      if (typeof DEBUG_SHOW_INTRODUCTION !== 'undefined') {
        this.showIntroduction(DEBUG_SHOW_INTRODUCTION);
      } else {
        this.showIntroduction(true);
      }
      this.showLoadingIndicator(false);
    }
  },

  // listens to a 'loadviewpoint' event
  onLoadViewpoint: function (event) {
    if (event.detail.viewpointRotation) {
      let camera = document.querySelector('[camera]').components['camera-controls'];
      camera.lookAt(event.detail.viewpointRotation);
    }
    this.loadBegin(event.detail.viewpointId, event.detail.viewpointPosition, event.detail.delay);
  },

  loadBegin: function (viewpointId, viewpointPosition, delay) {
    // don't load, if loading is still in progress
    if (this.isLoading) {
      return;
    }

    // don't load if the viewpoint id is not set
    if (isEmpty(viewpointId)) {
      return;
    }

    this.isLoading = true;

    this.sceneData.currentViewpointId = viewpointId;
    this.sceneData.previousViewpointId = this.viewpointId;
    this.sceneData.viewpointPosition = viewpointPosition;

    this.viewpointId = viewpointId;

    // set the webgl container inactive, to make sure inputs won't interrupt the process
    let webglContainer = document.querySelector('.webgl-content');
    webglContainer.classList.add('webgl-content--inactive');

    // load scene with or without delay
    delay = delay || 0.0;
    if (delay > 0.001) {
      setTimeout(this.loadTexture.bind(this, viewpointId, viewpointPosition), delay);
    } else {
      this.loadTexture(viewpointId, viewpointPosition);
    }
  },

  loadTexture: function (viewpointId, viewpointPosition) {
    const idLeft = viewpointId + '_l';
    const idRight = viewpointId + '_r';

    if (
      hasTexture(idLeft) &&
      !isLoadingTexture(idLeft) &&
      hasTexture(idRight) &&
      !isLoadingTexture(idRight)
    ) {
      let textureLeft = getTexture(idLeft);
      let textureRight = getTexture(idRight);
      this.loadSwitchViewpoint(textureLeft, textureRight, viewpointId, viewpointPosition);
    } else {
      this.showLoadingIndicator(true);

      getTexture(
        idLeft,
        this.getPanoramaPath(idLeft),
        { mipmaps: false },
        this.loadTextureSuccess.bind(this, 'left'),
        this.loadTextureError.bind(this)
      );

      getTexture(
        idRight,
        this.getPanoramaPath(idRight),
        { mipmaps: false },
        this.loadTextureSuccess.bind(this, 'right'),
        this.loadTextureError.bind(this)
      );
    }
  },

  loadTextureSuccess(type, texture) {
    if (!this.isLoading) {
      return;
    }

    if (type === 'left') {
      this.sceneData.textureLeft = texture;
    } else if (type === 'right') {
      this.sceneData.textureRight = texture;
    }

    if (this.sceneData.textureLeft !== null && this.sceneData.textureRight !== null) {
      this.loadSwitchViewpoint(
        this.sceneData.textureLeft,
        this.sceneData.textureRight,
        this.sceneData.currentViewpointId,
        this.sceneData.viewpointPosition
      );

      this.showLoadingIndicator(false);
    }
  },

  loadTextureError() {
    this.loadEnd(false);
  },

  loadSwitchViewpoint: function (textureLeft, textureRight, viewpointId, viewpointPosition) {
    if (this.isCompressionAvailable()) {
      let panorama = document.querySelector('a-entity[panorama]').components.panorama;
      panorama.setPanorama(
        textureLeft,
        textureRight,
        viewpointPosition,
        this.panoramaInitialized.bind(this, viewpointId)
      );
    } else {
      this.showLoadingIndicator(true);

      setTimeout(() => {
        let panorama = document.querySelector('a-entity[panorama]').components.panorama;
        panorama.setPanorama(
          textureLeft,
          textureRight,
          viewpointPosition,
          this.panoramaInitialized.bind(this, viewpointId)
        );
      }, 350);
    }
  },

  panoramaInitialized: function (viewpointId) {
    if (!this.isCompressionAvailable()) {
      this.showLoadingIndicator(false);
    }

    // fade out all contents
    let contentRoot = document.querySelector(this.data.contentRoot);
    let contents = contentRoot.querySelectorAll('[fade]');
    for (let i = 0; i < contents.length; ++i) {
      contents[i].components.fade.fadeOut();
    }

    // load the json file for the target viewpoint
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.addEventListener('load', this.loadJson.bind(this, xmlhttp, viewpointId));
    xmlhttp.open('GET', 'src/assets/data/contents/viewpoints/' + viewpointId + '.json', true);
    xmlhttp.send();
  },

  loadJson: function (request, viewpointId) {
    if (request.readyState === 4 && request.status === 200) {
      let viewpointMap = this.viewpointMap;
      let hotspotMap = this.hotspotMap;
      let contentRoot = document.querySelector(this.data.contentRoot);
      let jsonContent = JSON.parse(request.responseText);

      // track the new viewpoint
      const label = !isEmpty(jsonContent['tracking-label'])
        ? jsonContent['tracking-label']
        : this.viewpointId;
      setVirtualPageview(label, `Viewpoint: ${label}`);

      // create viewpoint marks
      for (let i = 0; i < jsonContent['viewpoint-marks'].length; ++i) {
        let current = jsonContent['viewpoint-marks'][i];

        let link = viewpointId + '__' + current.id;
        if (viewpointMap.has(link)) {
          let targetId = viewpointMap.get(link);
          let delay = 0.15 + i * 0.1;

          let type = isEmpty(current.type) ? '' : current.type;
          if (type === 'button') {
            this.createViewpointMarkButton(
              targetId,
              current.position,
              current.icon,
              delay,
              contentRoot
            );
          } else {
            this.createViewpointMark(targetId, current.position, delay, contentRoot);
          }
        }
      }

      // create hotspots
      for (let i = 0; i < jsonContent['hotspots'].length; ++i) {
        let current = jsonContent['hotspots'][i];

        if (hotspotMap.has(current.id)) {
          let content = hotspotMap.get(current.id);
          let delay = 0.15 + i * 0.1;

          this.createHotspot(content, current.position, delay, contentRoot);
        }
      }
    }

    setTimeout(this.loadEnd.bind(this), 100);
  },

  loadEnd: function () {
    // unset the loading flag
    this.isLoading = false;

    // reactive the webgl container to activate inputs
    let webglContainer = document.querySelector('.webgl-content');
    webglContainer.classList.remove('webgl-content--inactive');

    // reset data
    this.sceneData.textureLeft = null;
    this.sceneData.textureRight = null;
    this.sceneData.viewpointId = null;
    this.sceneData.previousViewpointId = null;
    this.sceneData.viewpointPosition = null;

    var event = new CustomEvent('viewpointloaded', { detail: { viewpointId: this.viewpointId } });
    window.dispatchEvent(event);

    // preload panoramas, when the first scene load finished
    if (!this.panoramasPreloaded) {
      this.preloadPanormas();
      this.panoramasPreloaded = true;
    }
  },

  createViewpointMark: function (targetId, position, delay, rootElement) {
    let viewpoint = document.createElement('a-entity');
    viewpoint.setAttribute('id', this.viewpointId + '_' + targetId);
    viewpoint.setAttribute('viewpoint-mark', { viewpoint: targetId });
    viewpoint.setAttribute('position', position);
    viewpoint.setAttribute('fade', { fadeInDelay: delay });
    rootElement.appendChild(viewpoint);
  },

  createHotspot: function (content, position, delay, rootElement) {
    let hotspot = document.createElement('a-entity');
    hotspot.setAttribute('id', this.viewpointId + '_' + content.id);
    hotspot.setAttribute('hotspot', { content: content });
    hotspot.setAttribute('position', position);
    hotspot.setAttribute('fade', { fadeInDelay: delay });
    rootElement.appendChild(hotspot);
  },

  showLoadingIndicator: function (active) {
    const event = new CustomEvent('loadingindicatoractive', { detail: { active } });
    window.dispatchEvent(event);
  },

  showIntroduction: function (active) {
    const event = new CustomEvent('introductionactive', { detail: { active } });
    window.dispatchEvent(event);
  },

  preloadPanormas: function () {
    this.textureLoadCounter -= 1;
    if (this.textureLoadCounter > 0) {
      return;
    }

    if (this.preloadId >= this.viewpointIds.length) {
      return;
    }

    const viewpointId = this.viewpointIds[this.preloadId];
    this.preloadId += 1;

    this.textureLoadCounter = 2;

    setTimeout(() => {
      getTexture(
        `${viewpointId}_l`,
        this.getPanoramaPath(`${viewpointId}_l`),
        { mipmaps: false },
        this.preloadPanormas.bind(this),
        this.preloadPanormas.bind(this)
      );
      getTexture(
        `${viewpointId}_r`,
        this.getPanoramaPath(`${viewpointId}_r`),
        { mipmaps: false },
        this.preloadPanormas.bind(this),
        this.preloadPanormas.bind(this)
      );
    }, 25);
  },

  getPanoramaPath(name) {
    const useCompression = this.isCompressionAvailable();
    if (useCompression) {
      return `src/assets/images/panoramas/${name}.basis`;
    } else {
      return `src/assets/images/raw/panoramas/${name}.jpg`;
    }
  },

  isCompressionAvailable() {
    // compressed panoramas are currently not supported on iOS
    // the pvrtc compression causes pixel bleeding, which results in a visual seam between the two hemispheres
    return false;
    const ios = isIOS();
    if (ios) {
      return false;
    }

    return true;
  },
});
