import { isEmpty } from '../utils/helper';

class GbMinimapGallery extends HTMLElement {
  constructor() {
    super();
    this.closeGallery = this.closeGallery.bind(this);
    this.render = this.render.bind(this);
    this.addEventListener = this.addEventListener.bind(this);
    this.jumpToView = this.jumpToView.bind(this);
    this._content = {};
    this._galleryItems = [];
  }

  set content(value) {
    this._content = value;
    this.afterConnectedCallback && this.render();
  }

  set galleryItems(value) {
    this._galleryItems = value;
  }

  get content() {
    return this._content;
  }

  connectedCallback() {
    this.render();
    this.addEventListener();
    this.afterConnectedCallback = true;
  }

  disconnectedCallback() {
    this.removeEventListener();
  }

  closeGallery() {
    var event = new CustomEvent('geberit.hideMinimapMenu');
    window.dispatchEvent(event);

    this.classList.add('gb-minimap-gallery--hidden');
  }

  jumpToView(id, rotation) {
    const galleryItem = this._galleryItems.find((el) => String(el.id) === id);
    const viewpointId = galleryItem.viewpoint.id;
    const viewpointPosition = new THREE.Vector3(
      galleryItem.viewpoint.position.x,
      galleryItem.viewpoint.position.y,
      galleryItem.viewpoint.position.z
    );
    var event = new CustomEvent('loadviewpoint', {
      detail: {
        viewpointId: viewpointId,
        viewpointPosition: viewpointPosition,
        viewpointRotation: rotation,
      },
    });
    this.closeGallery();
    window.dispatchEvent(event);
  }

  addEventListener() {
    let btn = this.querySelectorAll('.close-button, .button-continue');
    btn.forEach((b) => {
      b.addEventListener('click', this.closeGallery);
    });

    const galleryItems = this.querySelectorAll('.gb-minimap-gallery__content__item');
    galleryItems.forEach((item) => {
      const id = item.getAttribute('data-id');

      const rotation = {
        x: item.getAttribute('data-rotation-x'),
        y: item.getAttribute('data-rotation-y'),
        z: item.getAttribute('data-rotation-z'),
      };
      item.addEventListener('click', () => {
        this.jumpToView(id, rotation);
      });
    });
  }

  removeEventListener() {
    let btn = this.querySelectorAll('.close-button, .button-continue');
    btn.forEach((b) => {
      b.removeEventListener('click', this.closeGallery);
    });
  }

  render() {
    if (isEmpty(this._content)) {
      return;
    }

    let galleryItems = '';

    this._content.galleryItems
      .filter((item) => !item.hideMenu)
      .forEach((item) => {
        let itemData = this._galleryItems.find((elem) => elem.id === item.id);
        if (typeof itemData === 'undefined') return;
        galleryItems = galleryItems.concat(`<div 
      class='gb-minimap-gallery__content__item' 
      data-id='${item.id}' 
      data-rotation-x='${itemData.viewpoint.lookAt.x}' 
      data-rotation-y='${itemData.viewpoint.lookAt.y}' 
      data-rotation-z='${itemData.viewpoint.lookAt.z}'>
    <img src='${item.image}' alt='${item.image}' />
      <p>${item.title}</p>
    </div>`);
      });

    let html = `<div class='gb-minimap-gallery__container'>
                        <div class='gb-minimap-gallery__overlay'>
                            <div class='gb-minimap-gallery__overlay__button'>
                                <button class='close-button'><i class='web20-icon web20-icon-close'>
                                <span>${this._content.galleryCloseButton}</span></i></button>
                            </div>
                            <div class='gb-minimap-gallery__content'>
                                <h3>${this._content.galleryHeadline}</h3>
                                <div class='gb-minimap-gallery__content__items'>
                                  ${galleryItems}    
                                </div>
                                <div class='gb-minimap-gallery__content__button-continue'>
                                    <button id='map-continue' class='button button-continue'>
                                      ${this._content.galleryContinue}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>`;
    this.innerHTML = html;
  }
}

customElements.define('gb-minimap-gallery', GbMinimapGallery);
